function inViewportAndVisible(node, options, callback) {
  return throttle(() => {
    if (
      (options.checkIsVisible && inViewport(node) && isVisible(node)) ||
      (!options.checkIsVisible && inViewport(node))
    ) {
      callback();
    }
  }, options.delay);
}

function intersectionObserverFallback(node, delay) {
  return new Promise((resolve) => {
    const scrollListener = inViewportAndVisible(node, delay, () => {
      window.removeEventListener("scroll", scrollListener);
      window.removeEventListener("resize", scrollListener);
      resolve();
    });
    window.addEventListener("scroll", scrollListener, false);
    window.addEventListener("resize", scrollListener, false);
    scrollListener();
  });
}

function intersectionViewportObserver(node, options = {}) {
  const { checkIsVisible = true, delay = 500, threshold = [0.006] } = options;

  /* istanbul ignore else */
  if ("IntersectionObserver" in window) {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(
        (changes) => {
          changes.forEach((change) => {
            if (change.intersectionRatio > 0) {
              resolve();
              observer.unobserve(node);
            }
          });
        },
        {
          threshold,
        }
      );
      observer.observe(node);
    });
  } else {
    return intersectionObserverFallback(node, { delay, checkIsVisible });
  }
}

function getTaggingData(node, taggingData) {
  const { selectorData, attrData, staticData } = taggingData;
  let newTaggingData = {};
  selectorData?.forEach((item) => {
    let nkey = Object.keys(item)?.[0];
    let val = node.querySelector(Object.values(item)?.[0])?.innerText;
    newTaggingData[nkey] = val;
    newTaggingData = {
      ...newTaggingData,
      left: parseInt(node.getBoundingClientRect().left),
      top: parseInt(node.getBoundingClientRect().top),
    };
  });
  attrData?.forEach((item) => {
    if (item.selectorVal && item.attrs) {
      const selectedTag = node.querySelector(item.selectorVal);
      if (selectedTag) {
        Object.keys(item.attrs).forEach((keyValue, index) => {
          newTaggingData[keyValue] = selectedTag.getAttribute(
            Object.values(item.attrs)[index]
          );
        });
      }
    }
  });
  if (staticData) newTaggingData = { ...newTaggingData, ...staticData };
  return newTaggingData;
}

export function impressionTagging(selectorClassName, taggingData, eventData) {
  const productList = document.querySelectorAll(selectorClassName);
  if (productList && productList.length) {
    let pushedItemsCounter = 0;
    let impressionsList = [];
    productList.forEach((node) => {
      const newtaggingData = getTaggingData(node, taggingData);
      intersectionViewportObserver(node).then(() => {
        if (!node.getAttribute("impressionAdded")) {
          node.setAttribute("impressionAdded", true);
          pushedItemsCounter++;
          impressionsList.push(newtaggingData);
          if (pushedItemsCounter < 2) {
            setTimeout(() => {
              if (
                impressionsList.length == pushedItemsCounter &&
                impressionsList.length
              ) {
                impressionsList
                  .sort((a, b) => a.top - b.top || a.left - b.left)
                  .forEach((el) => {
                    delete el.left;
                    delete el.top;
                  });
                window.dataLayer.push({
                  ...eventData,
                  ecommerce: {
                    impressions: impressionsList,
                  },
                });
              }
              impressionsList = [];
              pushedItemsCounter = 0;
            }, 500);
          }
        }
      });
    });
  }
}

import EventDataLayer from "./components/v1/EventDataLayer";

const options = {
  disabled: false,
  domAnalyticsIdentifier: "data-analytics",
  domAnalyticsIdentifierClassname: "class",
  countDownSecound: 0,
  blacklistedLocators: [
    "#markerclusterer#",
    ".rfp-rr-most-ratings-list-unlike-datalayer-or",
    ".rfp-rr-most-ratings-list-like-datalayer-or",
  ],
};
const edl = new EventDataLayer(options);

edl.mount(window);

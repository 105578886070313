/**
 * @class EventDataLayer
 * @property {Object} wsf
 * @property {Array} globalLayer
 */

import { impressionTagging } from "./productImpressions";
export default class EventDataLayer {
  wsf;

  /**
   * Initial global variable declaration
   */
  static initialiseWsf(window) {
    window.wsf = {
      AnalyticsVariables: [],
      refappStoreFinder: {},
    };
  }
  static initialiseWsfOptions(window) {
    window.wsf.options = {};
  }

  /**
   * Some analytics variables are directly embedded on attributes on elements
   * in the DOM, so this method parses the DOM to get those values
   */
  getDomAnalyticsVariables(attributeIdentifier) {
    const analyticsElements = document.querySelectorAll(
      `[${attributeIdentifier}]`
    );
    const domAnalyticsVariables = [];

    for (const element of analyticsElements) {
      const values = JSON.parse(
        element.attributes[attributeIdentifier].nodeValue
      );
      domAnalyticsVariables.push(values);
    }
    return domAnalyticsVariables;
  }

  static defaultOptions = {
    disabled: false,
    blacklistedLocators: [],
    domAnalyticsIdentifier: null,
  };

  constructor(options = EventDataLayer.defaultOptions) {
    this.options = options;
  }

  static eventPropertiesReplacer(eventProperties, matchPatterns) {
    let eventPropertiesAsString = JSON.stringify(eventProperties);

    for (const [key, replaceValue] of Object.entries(matchPatterns)) {
      eventPropertiesAsString = eventPropertiesAsString.replaceAll(
        key,
        replaceValue
      );
    }
    return JSON.parse(eventPropertiesAsString);
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  pushToDataLayer(eventProperties) {
    const { refappMoreinformation, refappStoreFinder, refappRR, ecommerce } =
      this.wsf;

    const matchPatterns = {
      "[product name]":
        refappMoreinformation?.productName?.toLocaleLowerCase() ?? "",
      "[product id]": refappMoreinformation?.productId ?? "",
      "[page category]": refappMoreinformation?.pageCategory ?? "",
      "[retailer name]":
        refappMoreinformation?.retailerName?.toLocaleLowerCase() ?? "",

      "[zipcode or city]":
        refappStoreFinder?.zipcodeOrCity?.toLocaleLowerCase() ?? "",
      "[marker popup location]": refappStoreFinder?.markerPopupLocation ?? "",
      "[store name]": refappStoreFinder?.storeName?.toLocaleLowerCase() ?? "",

      "[write review button text]":
        refappRR?.writeReviewButtonText?.toLocaleLowerCase() ?? "",
      "[review list sort data]":
        refappRR?.reviewListSortData?.toLocaleLowerCase() ?? "",
      "[review list filter star]": refappRR?.reviewListfilterStar ?? "",
      "[review submit overall rating]":
        refappRR?.reviewSubmitOverallRating ?? "",
      "[url]": window?.location?.href ?? "",
      "::::": "::",
    };

    if (this.getKeyByValue(eventProperties, "[ecommerce]") !== undefined) {
      eventProperties[this.getKeyByValue(eventProperties, "[ecommerce]")] =
        ecommerce ? ecommerce : "";
    }

    if (!window.dataLayer) return false;

    window.dataLayer.push(
      EventDataLayer.eventPropertiesReplacer(eventProperties, matchPatterns)
    );
    return true;
  }

  domCssSelector(item, target) {
    if (target.matches(item.locatorQuery)) {
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domClassName(item, className) {
    if (
      new RegExp(
        "\\b" + item.locatorQuery.toLocaleLowerCase().trim() + "\\b"
      ).test(className.toLocaleLowerCase().trim())
    ) {
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domTextContent(item, textContent) {
    if (
      item.locatorQuery.toLocaleLowerCase().trim() ===
      textContent.toLocaleLowerCase()
    ) {
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domTagName(item, tagName) {
    if (
      item.locatorQuery.toLocaleLowerCase().trim() ===
      tagName.toLocaleLowerCase()
    ) {
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domOuterText(item, outerText) {
    if (
      item.locatorQuery.toLocaleLowerCase().trim() ===
      outerText.toLocaleLowerCase()
    ) {
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domInnerText(item, innerText) {
    if (
      item.locatorQuery.toLocaleLowerCase().trim() ===
      innerText.toLocaleLowerCase()
    ) {
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domInnerHTML(item, innerHTML) {
    if (
      item.locatorQuery.toLocaleLowerCase().trim() ===
      innerHTML.toLocaleLowerCase()
    ) {
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domId(item, ids) {
    if (
      item.locatorQuery.toLocaleLowerCase().trim() === ids.toLocaleLowerCase()
    ) {
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domScrollEvent(item) {
    if (this.wsf.options.countdown != "done") {
      this.wsf.options.countdown = "done";
      this.pushToDataLayer(item.eventProperties);
    }
  }

  domFocusEvent() {
    this.pushToDataLayer(item.eventProperties);
  }

  registerClickEvents(analyticsVariables) {
    this.wsf.options.getLocatorQuery =
      this.wsf?.options?.getLocatorQuery ?? false;
    const elements = document.querySelector("body");
    elements.addEventListener("click", (e) => {
      let parentClasses = [];
      if (e.target.getAttribute("data-googletag") != null) {
        if (!window.wsf) {
          window.wsf = { refappStoreFinder: {} };
        }
        delete window.wsf.refappStoreFinder;
        window.wsf.refappStoreFinder = {
          storeName: e.target.getAttribute("data-googletag"),
        };
      }
      let path = [];
      if (e.path == undefined) {
        path = e.composedPath && e.composedPath();
      } else {
        path = e.path;
      }
      // const path = e.path || (e.composedPath && e.composedPath());
      for (const element of path) {
        parentClasses.push(element.className);
      }
      if (this.wsf.options.getLocatorQuery) {
        console.table({
          ClassName: e.srcElement.className.trim(),
          ClassList: parentClasses,
          textContent: e.srcElement.textContent.trim(),
          tagName: e.srcElement.tagName.trim(),
          outerText: e.srcElement.outerText.trim(),
          innerText: e.srcElement.innerText.trim(),
          innerHTML: e.srcElement.innerHTML.trim(),
          id: e.srcElement.id.trim(),
        });
      }
      analyticsVariables.filter((item) => {
        if (!item.triggerOnExternalApiResponse) {
          switch (item.locatorType) {
            case "cssSelector":
              this.domCssSelector(item, e.target);
              break;
            case "className":
              const parentNodeList = [item.locatorQuery].filter((element) =>
                parentClasses.includes(element)
              );
              if (
                e.srcElement.hasAttribute("class") &&
                item.locatorQuery != parentNodeList[0]
              ) {
                this.domClassName(item, e.srcElement.className.trim());
              } else {
                if (item.locatorQuery === parentNodeList[0]) {
                  this.domClassName(item, item.locatorQuery.trim());
                }
              }
              break;
            case "textContent":
              this.domTextContent(item, e.srcElement.textContent.trim());
              break;
            case "tagName":
              this.domTagName(item, e.srcElement.tagName.trim());
              break;
            case "outerText":
              this.domOuterText(item, e.srcElement.outerText.trim());
              break;
            case "innerText":
              this.domInnerText(item, e.srcElement.innerText.trim());
              break;
            case "innerHTML":
              this.domInnerHTML(item, e.srcElement.innerHTML.trim());
              break;
            case "id":
              this.domId(item, e.srcElement.id.trim());
          }
        }
      });
    });
  }

  calculateScrollPercentage(target, childElemHeight) {
    try {
      if (!target) throw "scrolling element not found";

      let bool = target === document;

      let scrollHeight = (() => {
        if (bool) return childElemHeight;
        return target.scrollHeight;
      })();

      if (!scrollHeight) throw "child element not found";

      let clientHeight = bool ? window.innerHeight : target.clientHeight;
      let scrollTop = bool ? window.scrollY : target.scrollTop;

      let gottaScroll = scrollHeight - clientHeight;
      let percentage = Math.ceil((scrollTop / gottaScroll) * 100);
      return percentage;
    } catch (err) {
      console.error(err);
    }
  }

  countDownTime(eventProperties, self) {
    if (
      this.wsf.options.countDownSecound ===
        parseInt(eventProperties.timeduration) &&
      this.wsf.options.countdown != "done"
    ) {
      this.wsf.options.countdown = "done";
      self.domScrollEvent(eventProperties);
      window.clearInterval(this.wsf.options.countDownClearTime);
    }
  }

  countCalculation(eventProperties, self) {
    if (this.wsf.options.countdown != "done") {
      let counter = parseInt(eventProperties.timeduration);
      let newYearCountdown = setInterval(function () {
        counter--;
        if (counter === 0 && this.wsf.options.countdown != "done") {
          self.domScrollEvent(eventProperties);
          self.wsf.options.countdown = "done";
          clearInterval(newYearCountdown);
        }
      }, 1000);
    }
  }

  isScrollable(element) {
    if (
      (element.style.overflow !== undefined &&
        element.style.overflow !== "" &&
        (element.style.overflow == "scroll" ||
          element.style.overflow == "auto" ||
          element.style.overflowY == "scroll" ||
          element.style.overflowY == "auto")) ||
      (element.style.height !== undefined &&
        element.style.height !== "" &&
        element.style.height != "none") ||
      (element.style.maxHeight !== undefined &&
        element.style.maxHeight !== "" &&
        element.style.maxHeight != "none")
    ) {
      return true;
    } else {
      return false;
    }
  }

  registerScrollEvents(analyticsVariables) {
    this.wsf.options.countDownSecound = 0;
    this.wsf.options.countdown = "no";
    this.wsf.options.countDownClearTime = "";
    this.wsf.options.countdown = "yes";
    this.wsf.options.countdownSec = this.wsf?.options?.countdownSec ?? 30;
    this.wsf.options.pageScrollPercentage = this.wsf?.options?.Percentage ?? 75;
    analyticsVariables.filter((item) => {
      const elements = document.querySelector(item.locatorQuery);
      if (elements != null) {
        let childElement = document.querySelector(item.locatorQuery);
        if (childElement) {
          let childElemHeight = childElement.clientHeight;
          if (this.isScrollable(elements)) {
            elements.addEventListener(item.domEvent, (e) => {
              let scrollPercentage = this.calculateScrollPercentage(
                e.target,
                childElemHeight
              );
              this.countCalculation(item, this);
              if (
                scrollPercentage >=
                  parseInt(
                    item.scrollratio && item.scrollratio.length
                      ? item.scrollratio
                      : 75
                  ) &&
                this.wsf.options.countdown != "done"
              ) {
                this.domScrollEvent(item);
                this.wsf.options.countdown = "done";
                window.clearInterval(this.wsf.options.countDownClearTime);
              }
            });
          } else {
            window.addEventListener(item.domEvent, (e) => {
              if (
                Math.floor(childElement.getBoundingClientRect().bottom) <=
                  (document.documentElement.clientHeight ||
                    window.innerHeight) &&
                Math.floor(childElement.getBoundingClientRect().right) <=
                  (document.documentElement.clientWidth || window.innerWidth) &&
                this.wsf.options.countdown != "done"
              ) {
                this.domScrollEvent(item);
                this.wsf.options.countdown = "done";
                window.clearInterval(this.wsf.options.countDownClearTime);
              }
            });
          }
        }
      }
    });
  }

  inViewportAndVisible(node, options, callback) {
    return throttle(() => {
      if (
        (options.checkIsVisible && inViewport(node) && isVisible(node)) ||
        (!options.checkIsVisible && inViewport(node))
      ) {
        callback();
      }
    }, options.delay);
  }

  registerEvents(analyticsVariables) {
    const clickAnalyticsVariables = [];
    const scrollAnalyticsVariables = [];
    const impressionAnalyticsVariables = [];
    analyticsVariables.filter((item) => {
      switch (item.domEvent) {
        case "click":
          clickAnalyticsVariables.push(item);
          break;
        case "scroll":
          scrollAnalyticsVariables.push(item);
          break;
        case "focus":
          clickAnalyticsVariables.push(item);
          break;
        case "impression":
          impressionAnalyticsVariables.push(item);
          window.addEventListener("load", () => {
            var firstChild = document.querySelector(item.childSelector);
            var containerParent = document.querySelector(item.parentSelector);
            if (containerParent && firstChild) {
              var productRowsCount = Array.from(
                document.querySelectorAll(item.childSelector)
              ).length;
              impressionTagging(
                item.childSelector,
                item.taggingData,
                item.eventProperties
              );
              containerParent.addEventListener(
                "DOMSubtreeModified",
                (e) => {
                  setTimeout(() => {
                    if (
                      productRowsCount !=
                        Array.from(
                          document.querySelectorAll(item.childSelector)
                        ).length ||
                      firstChild !=
                        document.querySelectorAll(item.childSelector)[0]
                    ) {
                      productRowsCount = Array.from(
                        document.querySelectorAll(item.childSelector)
                      ).length;
                      firstChild = document.querySelector(item.childSelector);
                      impressionTagging(
                        item.childSelector,
                        item.taggingData,
                        item.eventProperties
                      );
                    }
                  }, 500);
                },
                false
              );
            }
          });
      }
    });
    this.registerClickEvents(clickAnalyticsVariables);
    this.registerScrollEvents(scrollAnalyticsVariables);
  }

  mount(window) {
    if (this.options.disabled) return;

    if (!window.wsf) {
      EventDataLayer.initialiseWsf(window);
    }

    if (!window.wsf.options) {
      EventDataLayer.initialiseWsfOptions(window);
    }

    this.wsf = window.wsf;
    const { AnalyticsVariables } = this.wsf;

    const domAnalyticsVariables = this.options.domAnalyticsIdentifier
      ? this.getDomAnalyticsVariables(this.options.domAnalyticsIdentifier)
      : [];

    if (domAnalyticsVariables.length != 0) {
      wsf.AnalyticsVariables.push(...domAnalyticsVariables);
    }

    const taggingVariables = [...AnalyticsVariables];

    if (taggingVariables.length <= 0) {
      return;
    }

    // To assign event
    window.addEventListener(
      "refapp-ajax-event",
      (evt) => {
        let locatorQuery = window.wsf.AnalyticsVariables.find(
          (o) => o.locatorQuery === evt.detail
        );
        if (locatorQuery) {
          this.domClassName(locatorQuery, evt.detail);
        }
      },
      false
    );

    this.registerEvents(AnalyticsVariables);
  }
}
